// model
import { eventNames } from "@/model/common/events/eventConst";

// design
import { downArrowWithTipLeft } from "@/design/symbols/htmlSymbols";

export const recordBadgeMixin = {
  data() {
    return {
      symbolShortcut: downArrowWithTipLeft
    };
  },
  props: {
    /**
     * @type {{id:number, name:string, categoryId:number, parentId:number, children:number, createdBy:string, creationDate:string, modificationDate:string, isDeleted:boolean, isDraft:boolean, isComposite:boolean, isLink:boolean, isLocked:boolean, isReadOnly:boolean, recordTypeId:number, recordType:string, stateId:number, state:string, version:number, versionDate:string, versionOwner:string, operations: {name: string, allowed: boolean, valid: boolean}[] }}
     */
    record: undefined,
    /**
     * Overlaps the slotted content on top of the component.
     * @type {boolean}
     */
    badgeOverlap: {
      type: Boolean,
      default: true
    },
    /**
     * Applies a 2px by default and 1.5px border
     * around the badge when using the dot property
     * @type {boolean}
     */
    badgeBordered: {
      type: Boolean,
      default: true
    },
    /**
     * Applies the light theme variant to the component.
     */
    badgeLight: {
      type: Boolean,
      default: true
    },
    badgeDark: {
      type: Boolean,
      default: false
    },
    /**
     * Removes badge padding for the use of the v-avatar in the badge slot
     */
    badgeAvatar: {
      type: Boolean,
      default: false
    },
    /**
     * disabled Record Icon
     * @type {boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Record icon Color Class
     * @type {string}
     */
    iconColorClass: {
      type: String,
      default: ""
    },
    /**
     * display as information Only
     * @type {boolean}
     */
    infoOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * computed Icon Color Class
     * @return {String|string|undefined}
     */
    computedIconColorClass() {
      return this.iconColorClass === ""
        ? this.recordIconColorClass(this.record)
        : this.iconColorClass;
    },
    /**
     * Determines whether provided record is Shortcut
     * @return {Boolean|boolean}
     */
    isShortcut() {
      return this.record?.isLink ?? false;
    }
  },
  methods: {
    /**
     * Handles click event
     */
    onClick() {
      this.$emit(eventNames.click);
    }
  }
};
