<template>
  <v-badge :value="isShortcut" bottom left overlap bordered>
    <!-- badge icon-->
    <template v-slot:badge>
      <span v-html="symbolShortcut" v-if="isShortcut"></span>
      <!-- remarks: using font-awesome, since v-badge :icon has issues at production -->
      <!--          <font-awesome-icon-->
      <!--            :icon="fontAwesomeSolidIcon(fasIcon.link)"-->
      <!--          ></font-awesome-icon>-->
    </template>
    <!-- component with-in badge-->
    <template v-slot:default>
      <record-badge-base
        :record="record"
        :badge-overlap="badgeOverlap"
        :badge-bordered="badgeBordered"
        :disabled="disabled"
        :info-only="infoOnly"
        :icon-color-class="iconColorClass"
        :is-history-mode="isHistoryMode"
      >
        <!-- icon -->
        <template v-slot:default>
          <slot name="default">
            <v-icon
              :disabled="disabled"
              :color="computedIconColorClass"
              @click="onClick"
              >{{ recordIcon(record) }}
            </v-icon>
          </slot>
        </template>
        <!-- tooltip -->
        <template v-slot:tooltip>
          <slot name="tooltip"></slot>
        </template>
      </record-badge-base>
    </template>
  </v-badge>
</template>

<script>
import { recordIconMixin } from "@/mixins/shared/record/recordIconMixin";
import { recordBadgeMixin } from "@/mixins/shared/record/recordBadgeMixin";

export default {
  name: "RecordBadgeIcon",
  mixins: [recordIconMixin, recordBadgeMixin],
  props: {
    isHistoryMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    RecordBadgeBase: () => import("@/components/record/RecordBadgeBase")
  }
};
</script>
